import React from "react";
import { Link, useNavigate } from "react-router-dom";
import linkedinIco from "../../assets/image/linkedinIco.png";
import TwitterIcon from "../../assets/image/TwitterIcon.png";
import instagramIcon from "../../assets/image/instagramIcon.png";
import tikTokIcon from "../../assets/image/tikTokIcon.png";
import Logo from "../../assets/image/IPCAFELogo.png";
const Footer = () => {
  const navigate = useNavigate();
  const GoToGetaway = (url) => {
    if (typeof window !== "undefined") {
      // window.location.href = url;
      window.open(url, "_blank");
    }
  };
  return (
    <footer style={{ marginTop: "auto" }}>
      <div className="FooterItem">
        <img
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/")}
          src={Logo}
          alt="logo"
        />
        <p>
          Your gateway to seamless cross-chain cryptocurrency exchanges.
          <br /> We find the best swapping rates for you, instantly!
        </p>
        <div
          style={{ display: "flex", alignItems: "center", marginTop: "18px" }}
        >
          <img
            src={TwitterIcon}
            style={{
              marginRight: "5.5px",
              cursor: "pointer",
              maxWidth: "40px",
            }}
            alt="logo of TwitterIcon"
            onClick={() => GoToGetaway("https://twitter.com/Turboswapio")}
          />
          <img
            src={instagramIcon}
            style={{
              marginRight: "5.5px",
              cursor: "pointer",
              maxWidth: "50px",
            }}
            alt="logo of instagramIcon"
            onClick={() =>
              GoToGetaway("https://www.instagram.com/turboswap.io")
            }
          />
          <img
            src={tikTokIcon}
            style={{
              marginRight: "5.5px",
              cursor: "pointer",
              maxWidth: "50px",
            }}
            alt="logo of medium"
            onClick={() => GoToGetaway("https://www.tiktok.com/@turboswapio")}
          />
          <img
            onClick={() =>
              GoToGetaway(
                "https://www.linkedin.com/company/turboswapio/?viewAsMember=true"
              )
            }
            src={linkedinIco}
            alt="logo of linkedIn"
            style={{ cursor: "pointer" }}
          />
        </div>
      </div>
      <div className="FooterItem2">
        <h5 style={{ cursor: "pointer" }} onClick={() => navigate("/")}>
          Turboswap
        </h5>
        <Link to="/about">About</Link>
        <a href="https://app.turboswap.io/#/FAQ" target="_self" >FAQ</a>
        <Link to="/terms-of-service">Terms and Conditions</Link>
        <Link to="/privacy-policy">Privacy Policy</Link>
        <Link to="#">White paper</Link>
      </div>
      <div className="FooterItem2">
        <h5>Links</h5>
        <a target="_blank" rel="noreferrer" href="https://fidao.org/">
          FIDAO
        </a>
        <a target="_blank" rel="noreferrer" href="https://sensifia.com/">
          Sensifia
        </a>
        <a target="_blank" rel="noreferrer" href="https://ipminter.com/">
          IPMinter
        </a>
        <a target="_blank" rel="noreferrer" href="https://ip-cafe.com/">
          IP-Cafe
        </a>
        <a target="_blank" rel="noreferrer" href="https://ip-captain.com/">
          IP-Captain
        </a>
      </div>
      <div className="FooterItem2">
        <h5>Get In Touch</h5>
        <p>
          Dammstrasse 16, 6300 Zug, Switzerland
          <br />
          Mail to: info@sensifia.vc
        </p>
      </div>
    </footer>
  );
};

export default Footer;
