import React, { useState } from "react";

import "./style.css";
import Header from "../components/header";
import Footer from "../components/footer";
import {
  Efficienc,
  Security,
  UserCentric,
  rateAggregation,
} from "../assets/image/about";

const AboutPage = () => {
  return (
    <div className="aboutPageContainer">
      <Header />
      <div className="AboutContainer">
        <h3>About Us</h3>
        <div className="AboutContent">
          <p>
            Welcome to TurboSwap.io – Your Premier Crypto Swapping Aggregator!
            At TurboSwap.io, we believe in empowering the global crypto
            community by providing a seamless and efficient platform for
            swapping digital assets. Our innovative decentralized application
            (DApp) aggregates rates from multiple decentralized exchanges (DEXs)
            in real-time, enabling users to access the best swapping rates
            effortlessly.
          </p>
        </div>
        <h4>Our Mission:</h4>
        <div className="AboutContent">
          <p>
            Our mission is to simplify and enhance the crypto swapping
            experience, making it more accessible and cost-effective for users
            worldwide. We strive to bridge the gap between decentralized finance
            (DeFi) and user-friendly interfaces, fostering a secure and
            transparent ecosystem.
          </p>
        </div>
        <h4>Key Features:</h4>
        <div className="AboutContent">
          <div className="FeaturesContainer">
            <h5>
              Rate Aggregation:
              <p>
                TurboSwap.io collects and compares rates from various DEXs,
                ensuring users get the most favorable rates for their crypto
                swaps.
              </p>
            </h5>

            <img src={rateAggregation} />
          </div>
          <div className="FeaturesContainer FeaturesContainerRevers">
            <img style={{marginRight:'5px'}} src={UserCentric} />
            <h5>
              User-Centric Design:
              <p>
                We prioritize user experience, providing an intuitive interface
                that caters to both novice and experienced crypto enthusiasts.
              </p>
            </h5>
          </div>
          <div className="FeaturesContainer">
            <h5>
              Security:
              <p>
                Your security is our top priority. TurboSwap.io employs
                cutting-edge security measures to safeguard your assets and
                personal information.
              </p>
            </h5>

            <img src={Security} />
          </div>
          <div className="FeaturesContainer FeaturesContainerRevers">
            <img style={{marginRight:'5px'}} src={Efficienc} />
            <h5>
              Efficiency:
              <p>
                TurboSwap.io accelerates the swapping process, allowing users to
                make quick and informed decisions.
              </p>
            </h5>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AboutPage;
