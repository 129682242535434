import React from "react";

import "./style.css";
import Header from "../components/header";
import Footer from "../components/footer";

const PrivacyPolicy = () => {
  return (
    <div className="privacyPageContainer">
      <Header />
      <div className="privacyContainer">
        <h3>Privacy Policy</h3>
        <ol>
          <li className="privacy-item">
            <h4>Information Collection</h4>
            <p>
              TurboSwap.io collects limited user information for account
              creation and transaction processing. This includes personal data
              such as email addresses and wallet addresses.
            </p>
          </li>
          <li className="privacy-item">
            <h4>Data Security</h4>
            <p>
              We employ industry-standard security measures to protect user
              data. Your information is encrypted and stored securely.
            </p>
          </li>
          <li className="privacy-item">
            <h4>Third-Party Services</h4>
            <p>
              TurboSwap.io may use third-party services for analytics and
              improvement purposes. These services adhere to their own privacy
              policies, and users are encouraged to review them.
            </p>
          </li>
          <li className="privacy-item">
            <h4>Cookies</h4>
            <p>
              TurboSwap.io uses cookies to enhance user experience. Users can
              manage cookie preferences through their browser settings.
            </p>
          </li>
          <li className="privacy-item">
            <h4>Updates to Privacy Policy</h4>
            <p>
              TurboSwap.io may update its Privacy Policy. Users will be notified
              of any changes, and continued use of our services implies
              acceptance of the updated policy.
            </p>
          </li>
        </ol>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
