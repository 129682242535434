import React, { useEffect, useState } from "react";
import "../en.css";
import CardsImage from "../assets/image/imageSection1.png";
import DotIco from "../assets/image/Dot.svg";
import { Link, useNavigate } from "react-router-dom";
import Token1 from "../assets/image/Tokens/Token1.svg";
import Token2 from "../assets/image/Tokens/Token2.svg";
import Token3 from "../assets/image/Tokens/Token3.svg";
import Token4 from "../assets/image/Tokens/Token4.svg";
import Token5 from "../assets/image/Tokens/Token5.svg";
import Token6 from "../assets/image/Tokens/Token6.svg";
import Token7 from "../assets/image/Tokens/Token7.svg";
import Token8 from "../assets/image/Tokens/Token8.svg";
import Token9 from "../assets/image/Tokens/Token9.svg";
import Token10 from "../assets/image/Tokens/Token10.svg";
import Token11 from "../assets/image/Tokens/Token11.svg";
import bgVideo from "../assets/image/bgVideo.png";
import FirstInvestmentPartners from "../assets/image/FirstInvestmentPartners.svg";
import southBankPartner from "../assets/image/southBankPartner.svg";
import SensifiaPartner from "../assets/image/SensifiaPartner.svg";
import SensifaiPartner from "../assets/image/SensifaiPartner.svg";
import PactusPartner from "../assets/image/PactusPartner.svg";
import Inventor from "../assets/image/Inventor.svg";
import Democratic from "../assets/image/Democratic.svg";
import Network from "../assets/image/Network.svg";
import Stramline from "../assets/image/Stramline.svg";
import Header from "../components/header";
import Footer from "../components/footer";
const Home = () => {
  const GoToGetaway = (url) => {

    if (typeof window !== "undefined") {
      // window.location.href = url;
      window.open(url, "_self");
    }
  };
  return (
    <div style={{ overflowX: "hidden" }}>
      <Header />
      <section className="section1-home">
        <div className="bgLinear"></div>

        <div className="title-text-section1">
          <h1>
            <span>
              Turboswap <br />
            </span>{" "}
            Decentralized Cryptocurrency Exchange
          </h1>
          <p>
            Welcome to Turboswap – the epitome of cross-chain cryptocurrency
            exchanges. Our groundbreaking platform seamlessly integrates DEXs
            and CEXs, ensuring you receive optimal rates without the usual
            hassle.
          </p>
          <div className="Cta-section1">
            <button onClick={() => GoToGetaway("https://app.turboswap.io/")}>
              Get Started
            </button>
            <div className="bgDot">
              <img src={DotIco} />
            </div>
          </div>
        </div>
        <div>
          <img className="imageSection1" src={CardsImage} />
        </div>
      </section>
      <section className="section3-home">
        <p>
          Swap Any Cryptocurrency, Anytime, on Any Chain!
          <br /> Experience Maximum Speed and Efficiency!
        </p>
      </section>
      <div>
        <div className="section3-tokens">
          <div>
            <img src={Token1} />
            <img src={Token2} />
            <img src={Token3} />
            <img src={Token4} />
            <img src={Token5} />
            <img src={Token6} />
          </div>
          <div className="mobileNone">
            <img src={Token7} />
            <img src={Token8} />
            <img src={Token9} />
            <img src={Token10} />
            <img src={Token11} />
          </div>
        </div>
      </div>
      <section className="sectionInventor-home">
        <div>
          <div>
            <img style={{ maxHeight: "100px" }} src={Inventor} />
            <p style={{ maxHeight: "100px" }}>Peak Rates</p>
          </div>
          <div>
            <img style={{ maxHeight: "100px" }} src={Network} />
            <p style={{ maxHeight: "100px" }}>Cross-Chain Genius</p>
          </div>
          <div>
            <img style={{ maxHeight: "100px" }} src={Democratic} />
            <p style={{ maxHeight: "100px" }}>Fortified Security</p>
          </div>
          <div>
            <img style={{ maxHeight: "100px" }} src={Stramline} />
            <p style={{ maxHeight: "100px" }}>Transparent Dealings</p>
          </div>
        </div>
        <div></div>
      </section>
      <section className="section4-home">
        <img src={bgVideo} className="bgVideo" />
        <div className="bgLinear"></div>
        <iframe
          width="65%"
          height="480"
          src="https://www.youtube.com/embed/b8gafXLb8yc?si=_CnLwBW4TtRyt6Fx"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
        {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/b8gafXLb8yc?si=_CnLwBW4TtRyt6Fx" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> */}
        <h3></h3>
      </section>
      <section className="section5-home">
        <h3>Our partners</h3>
        <div>
          <img src={SensifiaPartner} />
          <img src={FirstInvestmentPartners} />
          <img src={southBankPartner} />
          <img src={PactusPartner} />
          <img src={SensifaiPartner} />
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Home;
