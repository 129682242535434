import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import MenuIco from "../../assets/image/MenuIco.png";
import CloseIco from "../../assets/image/Close.png";
import Logo from "../../assets/image/IPCAFELogo.png";
const Header = () => {
  const [Menu, setMenu] = useState();
  const toggleMenu = () => {
    if (Menu) {
      setMenu(false);
    } else {
      setMenu(true);
    }
  };
  const navigate=useNavigate()
  return (
    <header className="header-container">
      <img style={{cursor:'pointer'}} onClick={()=>navigate('/')} src={Logo} alt="logo of Turboswap" />
      <div className="LinkContainer-Header">
        <Link style={{color:'rgb(119 119 119 / 73%)'}} to="#">White paper</Link>
        <Link to="/about">About</Link>
        {/* <Link to="/privacy-policy">Privacy Policy</Link>
        <Link to="/terms-of-condition">Terms of condition</Link> */}
        <a href="https://app.turboswap.io/#/FAQ" target="_self" >FAQ</a>
      </div>
      <div className="LoginBtn-container">
        <a href="https://app.turboswap.io/" className="loginBtn">
          Launch App
        </a>
      </div>
      <button className="MenuBtn" onClick={() => toggleMenu()}>
        {Menu ? (
          <img
            style={{ maxWidth: "32px" }}
            src={CloseIco}
            alt="menu close icon"
          />
        ) : (
          <img src={MenuIco} alt="menu icon" />
        )}
      </button>
      {Menu && (
        <div className="MenuContainer">
          <Link style={{color:'rgb(255 255 255 / 28%)'}} to="#">White paper</Link>
          <Link to="/about">About</Link>
          <a href="https://app.turboswap.io/#/FAQ" target="_self" >FAQ</a>
        </div>
      )}
    </header>
  );
};

export default Header;
