import React, { useState } from "react";

import "./style.css";
import Header from "../components/header";
import Footer from "../components/footer";

const TermsOfCondition = () => {
  return (
    <div className="termsPageContainer">
      <Header />
      <div className="termsContainer">
        <h3>Terms of Service</h3>
        <ol>
          <li className="terms-item">
            <h4>Acceptance of Terms</h4>
            <p>
              By using TurboSwap.io, you agree to comply with these Terms of
              Service. If you do not agree with any part of the terms, please
              refrain from using our services.
            </p>
          </li>
          <li className="terms-item">
            <h4>User Eligibility</h4>
            <p>
              You must be of legal age to use TurboSwap.io. By accessing our
              platform, you confirm that you meet the eligibility requirements.
            </p>
          </li>
          <li className="terms-item">
            <h4>Service Fees</h4>
            <p>
              TurboSwap.io may charge fees for its services. Users are
              responsible for understanding and accepting these fees before
              engaging in any transactions.
            </p>
          </li>
          <li className="terms-item">
            <h4>Security</h4>
            <p>
              TurboSwap.io prioritizes the security of user assets and personal
              information. Users are encouraged to follow recommended security
              practices and report any suspicious activities.
            </p>
          </li>
          <li className="terms-item">
            <h4>Disclaimer</h4>
            <p>
              TurboSwap.io is not responsible for the performance of external
              DEXs or the accuracy of rates displayed. Users are advised to
              conduct their own research before making any swapping decisions.
            </p>
          </li>
        </ol>
      </div>
      <Footer />
    </div>
  );
};

export default TermsOfCondition;
